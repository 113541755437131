import React, { useLayoutEffect, useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import axios from 'axios';
import { Button, Typography, Grid, FormControl, Select, MenuItem, Box, Snackbar, SnackbarContent, TextField, Dialog, DialogContent } from '@material-ui/core';
import { useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MyLocation } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import fr from 'date-fns/locale/fr';
import berlineImg from '../berline.jpeg';
import minivanImg from '../minivan.jpeg';
import greennImg from '../green.jpeg';
import CallButton from "../Components/CallButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: '#4caf50',
    },
    error: {
        backgroundColor: '#f44336',
    },
    iconButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center', // Centrer horizontalement
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    icon: {
        fontSize: '1.5rem',
    },
    datePickerContainer: {
        display: 'inline-block',
        width: 'auto',
    },
    datePicker: {
        padding: '10px 14px',
        fontSize: '1rem',
        width: 'auto',
        maxWidth: '90px', // Adjust the width as needed
    },
    datePickerDialog: {
        position: 'fixed',
        top: '10%', // Position higher on the screen
        transform: 'translateY(-10%)',
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
    },
    datePickerDialogContent: {
        display: 'flex',
        justifyContent: 'center', // Center calendar content horizontally
        alignItems: 'center', // Center calendar content vertically
        padding: 20,
    },
    timePickerDialogContent: {
        display: 'flex',
        justifyContent: 'center', // Center time picker content horizontally
        alignItems: 'center', // Center time picker content vertically
        padding: 20,
    },
    selectFormControl: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    typography: {
        fontSize: '1.25rem',
    },
    vehicleSelection: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    vehicleOption: {
        cursor: 'pointer',
        textAlign: 'center',
        border: '2px solid transparent',
        borderRadius: '8px',
        padding: '10px',
        '&.selected': {
            borderColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
    },
    vehicleImage: {
        width: '100px',
        height: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: '150px',
        },
    },
    container: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            maxWidth: '600px',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '800px',
        },
    },
    availabilityMessage: {
        marginTop: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
        textAlign: 'center',
    },
    summaryBox: {
        maxWidth: 500,
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    },
    additionalInfo: {
        marginTop: '10px',
        fontStyle: 'italic',
        color: theme.palette.text.secondary,
    },
}));

export const Reserver = () => {
    const classes = useStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [distance, setDistance] = useState(null);
    const [vehicleType, setVehicleType] = useState('berline');
    const [numPassengers, setNumPassengers] = useState(1);
    const [numBags, setNumBags] = useState(0);
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('success');

    const [departureDate, setDepartureDate] = useState(new Date());
    const [departureTime, setDepartureTime] = useState(new Date());

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const handleDatePickerOpen = () => setOpenDatePicker(true);
    const handleDatePickerClose = () => setOpenDatePicker(false);

    const handleDateChange = (date) => {
        setDepartureDate(date);
        handleDatePickerClose();
    };

    const [openTimePicker, setOpenTimePicker] = useState(false);

    const handleTimePickerOpen = () => setOpenTimePicker(true);
    const handleTimePickerClose = () => setOpenTimePicker(false);

    const handleTimeChange = (time) => {
        setDepartureTime(time);
        handleTimePickerClose();
    };

    useEffect(() => {
        const now = new Date();
        if (
            departureDate.getDate() === now.getDate() &&
            departureDate.getMonth() === now.getMonth() &&
            departureDate.getFullYear() === now.getFullYear()
        ) {
            const newTime = new Date();
            newTime.setMinutes(newTime.getMinutes() + 30);
            setDepartureTime(newTime);
        }
    }, [departureDate]);

    const calculateMinTime = () => {
        const today = new Date();
        if (
            departureDate.getDate() === today.getDate() &&
            departureDate.getMonth() === today.getMonth() &&
            departureDate.getFullYear() === today.getFullYear()
        ) {
            const minimumDate = new Date();
            minimumDate.setMinutes(minimumDate.getMinutes() + 30);
            return minimumDate;
        }
        return new Date(today.setHours(0, 0, 0, 0)); // Min time for future dates is midnight
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleOriginSelected = async (place) => {
        if (place) {
            // Récupérer les détails du lieu de manière asynchrone
            const placeDetails = await fetchAddressFromPlaceId(place.place_id);

            setOrigin({
                name: placeDetails.name || 'Nom indisponible',
                display_address: placeDetails.name ? `${placeDetails.name }, ${placeDetails.address || ''}` : `${placeDetails.address }`,
                formatted_address: place.formatted_address,
                place_id: place.place_id,
                location: placeDetails.location,
            });
        }
    };
    const handleDestinationSelected = async (place) => {
        if (place) {
            const placeDetails = await fetchAddressFromPlaceId(place.place_id);

            setDestination({
                name: placeDetails.name || 'Nom indisponible',
                display_address: placeDetails.name ? `${placeDetails.name }, ${placeDetails.address || ''}` : `${placeDetails.address }`,
                formatted_address: place.formatted_address,
                place_id: place.place_id,
                location: placeDetails.location,
            });
        }
    };


    const geocodeAddress = async (address) => {
        try {
            if (!address) {
                throw new Error('Invalid address');
            }

            let coords;

            if (address.place_id) {
                // Si place_id est défini, utilisez l'API /place-coordinates
                const response = await axios.get(`${apiUrl}/place-coordinates`, {
                    params: { place_id: address.place_id },
                });

                if (response.data && response.data.lat && response.data.lng) {
                    coords = `${response.data.lat},${response.data.lng}`; // Retourne les coordonnées en format "lat,lng"
                } else {
                    throw new Error('Erreur lors de la récupération des coordonnées à partir du place_id');
                }
            } else if (address.formatted_address) {
                // Si place_id n'est pas défini, utilisez l'API /geocode-address
                const response = await axios.get(`${apiUrl}/geocode-address`, {
                    params: { address: address.formatted_address },
                });

                if (response.data && response.data.lat && response.data.lng) {
                    coords = `${response.data.lat},${response.data.lng}`;
                } else {
                    throw new Error('Erreur lors de la récupération des coordonnées à partir de l\'adresse');
                }
            } else {
                throw new Error('Ni place_id ni formatted_address n\'est disponible');
            }

            return coords; // Retourne les coordonnées
        } catch (error) {
            console.error('Erreur lors de la géocodification de l\'adresse :', error.message);
            throw error;
        }
    };
    


    const [loadingCost, setLoadingCost] = useState(false);

    const calculateDistance = async () => {
        setLoadingCost(true);
        try {
            const originCoords = await geocodeAddress(origin);
            const destinationCoords = await geocodeAddress(destination);

            // Check if it's a weekend, holiday, or nighttime
            const isWeekendOrHoliday = checkWeekendOrHoliday(departureDate);
            const isNighttime = checkNighttime(departureTime);

            // Base rate per kilometer and fixed cost
            let costPerKilometer = 2.5;
            const fixedCost = 10;

            // Adjust rate based on vehicle type, weekend/holiday, and nighttime
            if (vehicleType === 'minivan') {
                costPerKilometer += 0.60;
            }
            if (isWeekendOrHoliday || isNighttime) {
                costPerKilometer += 0.5;
            }

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/directions`, {
                params: {
                    origin: originCoords,
                    destination: destinationCoords,
                },
            });

            if (!response.data || !response.data.distance || !response.data.duration) {
                throw new Error('Distance or duration data is missing from API response.');
            }

            const distanceInMeters = response.data.distance;
            const distanceInKilometers = distanceInMeters / 1000;
            const durationInSeconds = response.data.duration;
            const durationInMinutes = Math.ceil(durationInSeconds / 60);

            // Calculate the total cost with adjustments
            let totalCost = (distanceInKilometers * costPerKilometer + fixedCost).toFixed(2);
            // Round the totalCost to the nearest .50 or whole number
            totalCost = Math.ceil(totalCost * 2) / 2;

            const result = {
                kilometers: distanceInKilometers.toFixed(2),
                cost: totalCost.toFixed(2), // Ensure two decimal places for display
                duration: durationInMinutes,
            };

            setDistance(result);
            drawRouteOnMap(originCoords, destinationCoords);

            return result; // Explicitly return the result
        } catch (error) {
            console.error('Erreur lors du calcul de la distance:', error.message);
            setDistance(null);
            setSnackbarMessage('Impossible de calculer la distance. Veuillez vérifier les adresses saisies.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
            return null; // Return null on error
        } finally {
            setLoadingCost(false);
        }
    };



    const checkWeekendOrHoliday = (date) => {
        const dayOfWeek = date.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
            return true;
        }

        const holidays = {
            '01-01': 'Jour de l\'an',
            '05-01': 'Fête du Travail',
            '05-08': 'Armistice 1945',
            '07-14': 'Fête Nationale',
            '08-15': 'Assomption',
            '11-01': 'Toussaint',
            '11-11': 'Armistice 1918',
            '12-25': 'Noël',
        };

        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        if (formattedDate in holidays) {
            return true;
        }

        return false;
    };

    const checkNighttime = (time) => {
        const hours = time.getHours();
        if (hours >= 20 || hours < 6) {
            return true;
        }
        return false;
    };

    const options = {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: 'fr' },
    };
    
    const drawRouteOnMap = (originCoords, destinationCoords) => {
        if (window.google && originCoords && destinationCoords) {
            const directionsService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer();
            const map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: { lat: 47.2184, lng: -1.5536 },
            });
            directionsRenderer.setMap(map);

            const request = {
                origin: originCoords,
                destination: destinationCoords,
                travelMode: 'DRIVING',
            };

            directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result);
                } else {
                    console.error('Error fetching directions:', status);
                }
            });
        }
    };

    const [isReserving, setIsReserving] = useState(false); // Loading state for reservation
    const handleReservation = async () => {
        setIsReserving(true);

        if (!origin || !destination || !origin.place_id || !destination.place_id) {
            setSnackbarMessage('Veuillez sélectionner une adresse de départ et une adresse de destination.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
            setIsReserving(false);
            return;
        }

        try {
            const distanceData = await calculateDistance();
            if (!distanceData) {
                throw new Error('Erreur lors du calcul de la distance');
            }

            const { kilometers, cost, duration } = distanceData;

            const url = `/confirmation-reservation?origin=${encodeURIComponent(origin.place_id)}&destination=${encodeURIComponent(destination.place_id)}&dateDepart=${encodeURIComponent(departureDate.toISOString())}&heureDepart=${encodeURIComponent(departureTime.toISOString())}&vehicleType=${encodeURIComponent(vehicleType)}&numPassengers=${numPassengers}&numBags=${numBags}&distance=${encodeURIComponent(kilometers)}&cost=${encodeURIComponent(cost)}&duration=${encodeURIComponent(duration)}`;

            navigate(url);
        } catch (error) {
            console.error("Erreur lors de la réservation :", error.message);
            setSnackbarMessage("Impossible de réserver. Veuillez réessayer.");
            setSnackbarColor("error");
            setSnackbarOpen(true);
        } finally {
            setIsReserving(false);
        }
    };


    // Fetch the addresses for both the origin and destination using place_ids
    const fetchAddressFromPlaceId = async (placeId) => {
        try {
            const response = await axios.get(`${apiUrl}/place-address`, {
                params: { place_id: placeId },
            });

            const { address, name, url } = response.data; // Récupère `name` et `address`

            return { address, name, url };
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'adresse :', error);
            return { address: 'Adresse introuvable', name: '' };
        }
    };

    const handleGeolocate = async (isOrigin) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                try {
                    // Appel de votre API Node.js pour obtenir le place_id et l'adresse
                    const response = await axios.get(`${apiUrl}/geocode`, {
                        params: {
                            lat: latitude,
                            lng: longitude,
                        },
                    });

                    if (response.data) {
                        const { place_id, address } = response.data;

                        // Mettre à jour l'origine ou la destination en fonction du bouton cliqué
                        if (isOrigin) {
                            setOrigin({
                                formatted_address: address,
                                place_id: place_id,
                                display_address: address, // Affiche l'adresse dans Autocomplete
                            });
                        } else {
                            setDestination({
                                formatted_address: address,
                                place_id: place_id,
                                display_address: address, // Affiche l'adresse dans Autocomplete
                            });
                        }
                    } else {
                        throw new Error('Aucune adresse trouvée pour ces coordonnées');
                    }
                } catch (error) {
                    console.error('Erreur lors de la géolocalisation:', error.message);
                    setSnackbarMessage('Erreur lors de la géolocalisation.');
                    setSnackbarColor('error');
                    setSnackbarOpen(true);
                }
            }, (error) => {
                console.error('Erreur lors de la récupération de la position:', error.message);
                setSnackbarMessage('Erreur lors de la récupération de votre position.');
                setSnackbarColor('error');
                setSnackbarOpen(true);
            });
        } else {
            console.error('La géolocalisation n\'est pas prise en charge par votre navigateur.');
            setSnackbarMessage('La géolocalisation n\'est pas prise en charge par votre navigateur.');
            setSnackbarColor('error');
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>
                Estimez votre trajet
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="outlined" className={classes.typography}>Adresse de prise en charge:</Typography>
                    <Box display="flex" alignItems="center">
                        <Autocomplete
                            apiKey={API_KEY}
                            style={{ width: '90%', fontSize: '1.2rem' }}
                            onPlaceSelected={handleOriginSelected}
                            types={['geocode']}
                            options={options}
                            value={origin ? origin.display_address : ''}
                            onChange={(e) => setOrigin({ display_address: e.target.value, place_id: '' })}
                            onClick={() => setOrigin({ display_address: '', place_id: '' })}
                            renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                        />
                        <button
                            className={classes.iconButton}
                            onClick={() => handleGeolocate(true)}
                        >
                            <MyLocation className={classes.icon} />
                        </button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="outlined" className={classes.typography}>Adresse de destination:</Typography>
                    <Box display="flex" alignItems="center">
                        <Autocomplete
                            apiKey={API_KEY}
                            style={{ width: '90%', fontSize: '1.2rem' }}
                            onPlaceSelected={handleDestinationSelected}
                            types={['geocode']}
                            options={options}
                            value={destination ? destination.display_address : ''}
                            onChange={(e) => setDestination({ display_address: e.target.value, place_id: '' })}
                            onClick={() => setDestination({ display_address: '', place_id: '' })}
                            renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
                        />
                        <button
                            className={classes.iconButton}
                            onClick={() => handleGeolocate(false)}
                        >
                            <MyLocation className={classes.icon} />
                        </button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Prise en charge le : </Typography>
                    <Box
                        className={classes.datePickerContainer}
                        onClick={() => isMobile && handleDatePickerOpen()}
                    >
                        {!isMobile && (
                            <DatePicker
                                selected={departureDate}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                locale={fr}
                                minDate={new Date()}
                                className={classes.datePicker}
                            />
                        )}
                        {isMobile && (
                            <TextField
                                variant="outlined"
                                fullWidth
                                onClick={handleDatePickerOpen}
                                value={departureDate ? departureDate.toLocaleDateString('fr-FR') : ''}
                                placeholder="Sélectionner une date"
                                readOnly
                            />
                        )}
                    </Box>

                    {/* Dialog for mobile */}
                    <Dialog
                        open={isMobile && openDatePicker}
                        onClose={handleDatePickerClose}
                        fullWidth
                        maxWidth="xs"
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: '10%', // Adjusts the dialog to appear higher on mobile
                                transform: 'translateY(-10%)',
                            },
                        }}
                    >
                        <DialogContent className={classes.datePickerDialogContent}>
                            <DatePicker
                                selected={departureDate}
                                onChange={handleDateChange}
                                inline
                                locale={fr}
                                minDate={new Date()}
                            />
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Prise en charge à : </Typography>
                    <Box
                        className={classes.datePickerContainer}
                        onClick={() => isMobile && handleTimePickerOpen()}
                    >
                        {!isMobile && (
                            <DatePicker
                                selected={departureTime}
                                onChange={handleTimeChange}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Heure"
                                dateFormat="HH:mm"
                                locale={fr}
                                minTime={calculateMinTime()} // Restrict time selection based on date
                                maxTime={new Date().setHours(23, 59)} // Max time is 23:59
                                className={classes.datePicker}
                            />
                        )}
                        {isMobile && (
                            <TextField
                                variant="outlined"
                                fullWidth
                                onClick={handleTimePickerOpen}
                                value={departureTime ? departureTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) : ''}
                                placeholder="Sélectionner une heure"
                                readOnly
                            />
                        )}
                    </Box>

                    {/* Dialog for mobile time picker */}
                    <Dialog
                        open={isMobile && openTimePicker}
                        onClose={handleTimePickerClose}
                        fullWidth
                        maxWidth="xs"
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: '10%', // Adjusts the dialog to appear higher on mobile
                                transform: 'translateY(-10%)',
                            },
                        }}
                    >
                        <DialogContent className={classes.timePickerDialogContent}>
                            <DatePicker
                                selected={departureTime}
                                onChange={handleTimeChange}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Heure"
                                dateFormat="HH:mm"
                                locale={fr}
                                inline
                                minTime={calculateMinTime()} // Restrict time selection based on date
                                maxTime={new Date().setHours(23, 59)} // Max time is 23:59
                            />
                        </DialogContent>
                    </Dialog>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Nombre de passagers:</Typography>
                    <FormControl className={classes.selectFormControl}>
                        <Select
                            labelId="num-passengers-label"
                            style={{ width: '25%' }}
                            value={numPassengers}
                            onChange={(e) => setNumPassengers(e.target.value)}
                        >
                            {[...Array(10).keys()].map(n => (
                                <MenuItem key={n + 1} value={n + 1}>{n + 1}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="outlined" className={classes.typography}>Nombre de bagages:</Typography>
                    <FormControl className={classes.selectFormControl}>
                        <Select
                            labelId="num-bags-label"
                            value={numBags}
                            style={{ width: '25%' }}
                            onChange={(e) => setNumBags(e.target.value)}
                        >
                            {[...Array(10).keys()].map(n => (
                                <MenuItem key={n} value={n}>{n}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="outlined" className={classes.typography}>Type de véhicule:</Typography>
                    <Box className={classes.vehicleSelection}>
                        <Box className={`${classes.vehicleOption} ${vehicleType === 'berline' ? 'selected' : ''}`}
                             onClick={() => setVehicleType('berline')}>
                            <img src={berlineImg} alt="Berline" className={classes.vehicleImage} />
                            <Typography variant="body1">Berline</Typography>
                        </Box>
                        <Box className={`${classes.vehicleOption} ${vehicleType === 'green' ? 'selected' : ''}`}
                             onClick={() => setVehicleType('green')}>
                            <img src={greennImg} alt="Green" className={classes.vehicleImage} />
                            <Typography variant="body1">Électrique ou Hybride</Typography>
                            {vehicleType === 'green' && (
                                <Typography variant="body2" className={classes.availabilityMessage}>
                                    Sur demande et sous réserve de disponibilité
                                </Typography>
                            )}
                        </Box>
                        <Box className={`${classes.vehicleOption} ${vehicleType === 'minivan' ? 'selected' : ''}`}
                             onClick={() => setVehicleType('minivan')}>
                            <img src={minivanImg} alt="Minivan" className={classes.vehicleImage} />
                            <Typography variant="body1">Minivan</Typography>
                            {vehicleType === 'minivan' && (
                                <Typography variant="body2" className={classes.availabilityMessage}>
                                    Sur demande et sous réserve de disponibilité
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={calculateDistance}
                        disabled={loadingCost || !origin || !destination}
                        className={classes.button}
                    >
                        {loadingCost ? 'Calcul en cours...' : 'Calculer le coût'}
                    </Button>

                </Grid>
                {distance && (
                    <Box
                        boxShadow={3}
                        borderRadius={8}
                        p={3}
                        bgcolor="background.paper"
                        textAlign="center"
                        mt={3}
                        className={classes.summaryBox}
                    >
                        <Typography variant="h5" color="textPrimary" gutterBottom>
                            Résumé de votre trajet
                        </Typography>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="textSecondary">
                                    <MyLocation fontSize="small" /> Distance
                                </Typography>
                                <Typography variant="h6" color="textPrimary">
                                    {distance.kilometers} km
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="textSecondary">
                                    <span role="img" aria-label="Cost"> €</span> Coût total
                                </Typography>
                                <Typography variant="h6" color="textPrimary">
                                    {distance.cost} €
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="textSecondary">
                                    <span role="img" aria-label="Duration">🕒</span> Durée estimée
                                </Typography>
                                <Typography variant="h6" color="textPrimary">
                                    {distance.duration} min
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            mt={2}
                            className={classes.additionalInfo}
                        >
                            * Les durées et coûts sont estimés et peuvent varier selon les conditions de circulation et la disponibilité des véhicules.
                        </Typography>
                    </Box>
                )}

                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        onClick={handleReservation}
                        startIcon={<AccessTimeIcon />} // Icône d'horloge à gauche du texte
                        style={{ backgroundColor: '#B5DAFC', color: 'black' }}
                        disabled={isReserving} // Disable button during reservation
                    >
                        {isReserving ? 'Nous y sommes presque...' : 'Réserver'}
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <CallButton phoneNumber={process.env.REACT_APP_BUSINESS_PHONE} />
                </Grid>


            </Grid>
            <div id="map" style={{ height: distance ? '400px' : '0px', marginTop: '20px' }}></div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <SnackbarContent
                    className={classes[snackbarColor]}
                    message={snackbarMessage}
                />
            </Snackbar>
        </>
    );
};
